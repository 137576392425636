define('fti-pump-selector/services/pump-application', ['exports', 'fti-pump-selector/macros/default-value', 'fti-pump-selector/utils/conversions'], function (exports, _defaultValue, _conversions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    _pump_seri: null,
    _motor_speed: null,
    _motor_size: null,
    _motor_adapter: null,
    _motor_phase: null,
    _motor_type: null,
    _fluid: null,
    _units: null,
    _temperature: null,
    _temperatureUnit: null,
    _viscosity: null,
    _viscosity_unit: null,
    _express_head_in_pressure: null,

    // Data Objects
    units_available: Ember.A([{
      value: 1,
      label: 'English (gpm, ft, hp, psi)'
    }, {
      value: 2,
      label: 'SI (cmph, m, kW, kPA)'
    }, {
      value: 3,
      label: 'SI (cmph, m, kW, bar)'
    }, {
      value: 4,
      label: 'SI (lpm, m, kW, kPa)'
    }, {
      value: 5,
      label: 'SI (lpm, m, kW, bar)'
    }]),
    units_default: Ember.computed('units_available', function () {
      return this.get('units_available.firstObject');
    }),

    viscosity_units: Ember.A([{
      value: 'centipoise',
      label: 'Centipoise'
    }, {
      value: 'centistokes',
      label: 'Centistokes'
    }, {
      value: 'ssu',
      label: 'SSU'
    }]),
    viscosity_unit_default: Ember.computed('viscosity_units', function () {
      return this.get('viscosity_units.firstObject');
    }),
    viscosity_default: 1,

    temperatureUnits: Ember.A([{
      value: 'F',
      label: 'Fahrenheit'
    }, {
      value: 'C',
      label: 'Celcius'
    }]),
    temperature_units_default: Ember.computed('temperatureUnits', function () {
      return this.get('temperatureUnits.firstObject');
    }),
    temperature_unit: Ember.computed('temperature_unit_selected', function () {
      return this.get('temperature_unit_selected.value');
    }),
    temperature_default: 68,

    flow_unit: Ember.computed('units', function () {
      var unit_value = this.get('units.value');

      if (unit_value === 2 || unit_value === 3) {
        return 'CMPH';
      }
      if (unit_value === 4 || unit_value === 5) {
        return 'LPM';
      }

      // Default return or value == 1
      return 'GPM';
    }),

    length_unit: Ember.computed('units', function () {
      var unit_value = this.get('units.value');

      if (unit_value === 2 || unit_value === 3 || unit_value === 4 || unit_value === 5) {
        return 'M';
      }

      // Default return or value == 1
      return 'FT';
    }),

    head_unit: Ember.computed('units', 'express_head_in_pressure', function () {
      var unit_value = this.get('units.value');

      if (this.get('express_head_in_pressure')) {
        if (unit_value === 2 || unit_value === 4) {
          return 'KPA';
        }
        if (unit_value === 3 || unit_value === 5) {
          return 'BAR';
        }

        // Default return or value == 1
        return 'PSI';
      } else {
        if (unit_value === 2 || unit_value === 3 || unit_value === 4 || unit_value === 5) {
          return 'M';
        }

        // Default return or value == 1
        return 'FT';
      }
    }),

    power_unit: Ember.computed('units', function () {
      var unit_value = this.get('units.value');

      if (unit_value === 2 || unit_value === 3 || unit_value === 4 || unit_value === 5) {
        return 'KW';
      }

      // Default return or value == 1
      return 'HP';
    }),

    pressure_unit: Ember.computed('units', function () {
      var unit_value = this.get('units.value');

      if (unit_value === 2 || unit_value === 4) {
        return 'KPA';
      }
      if (unit_value === 3 || unit_value === 5) {
        return 'BAR';
      }

      // Default return or value == 1
      return 'PSI';
    }),

    frequencies: Ember.A([{ value: 50, label: '50 Hz' }, { value: 60, label: '60 Hz' }]),
    frequency_default: Ember.computed('frequencies', function () {
      return this.get('frequencies').findBy('value', 60);
    }),

    pump_series: Ember.A([Ember.Object.create({ value: 'DB', label: 'DB/MSDB', is_disabled: false }), Ember.Object.create({ value: 'SP', label: 'SP', is_disabled: false }), Ember.Object.create({ value: 'UCR', label: 'UCR', is_disabled: false }), Ember.Object.create({ value: 'UCP', label: 'UCP', is_disabled: false }), Ember.Object.create({
      value: 'AC',
      label: 'AC (Does not verify chemical compatibility!)',
      is_disabled: false
    })]
    // EmberObject.create({ value: 'MSDB', label: 'MSDB', is_disabled: false }),
    ),
    pump_seri_default: Ember.computed('pump_series', function () {
      return this.get('pump_series.firstObject');
    }),

    motor_speeds: Ember.A([{
      value: 'high',
      label: 'High'
    }, {
      value: 'low',
      label: 'Low'
    }, {
      value: 'both',
      label: 'Both'
    }]),
    motor_speed_default: Ember.computed('motor_speeds', function () {
      return this.get('motor_speeds.firstObject');
    }),

    motor_sizes: Ember.A([{
      value: 'design_point',
      label: 'Design Point'
    }, {
      value: 'non_overloading',
      label: 'Non-overloading'
    }]),
    motor_size_default: Ember.computed('motor_sizes', function () {
      return this.get('motor_sizes.firstObject');
    }),

    motor_adapters: Ember.A([{
      value: 'nema',
      label: 'NEMA'
    }, {
      value: 'iec',
      label: 'IEC'
    }]),
    motor_adapter_default: Ember.computed('motor_adapters', function () {
      return this.get('motor_adapters.firstObject');
    }),

    motor_phases: Ember.A([{
      value: 1,
      label: 'Single Phase'
    }, {
      value: 3,
      label: 'Three Phase'
    }]),
    motor_phase_default: Ember.computed('motor_phases', function () {
      return this.get('motor_phases').findBy('value', 3);
    }),

    motor_types: Ember.A([{
      value: 'tefc',
      label: 'TEFC'
    }, {
      value: 'explosion_proof',
      label: 'Explosion Proof'
    }, {
      value: 'chem_duty',
      label: 'Chemical Duty'
    }, {
      value: 'wash_down',
      label: 'Wash Down'
    }]),
    motor_type_default: Ember.computed('motor_types', function () {
      return this.get('motor_types.firstObject');
    }),

    express_head_in_pressure_default: false,
    wet_end_only_default: false,
    include_motors_default: false,
    // End Data Objects

    units: (0, _defaultValue.default)('units_default'),
    viscosity: (0, _defaultValue.default)('viscosity_default'),
    viscosity_unit: (0, _defaultValue.default)('viscosity_unit_default'),
    temperature_unit_selected: (0, _defaultValue.default)('temperature_units_default'),
    temperature: (0, _defaultValue.default)('temperature_default'),
    frequency: (0, _defaultValue.default)('frequency_default'),
    pump_seri: (0, _defaultValue.default)('pump_seri_default'),
    motor_speed: (0, _defaultValue.default)('motor_speed_default'),
    motor_size: (0, _defaultValue.default)('motor_size_default'),
    motor_adapter: (0, _defaultValue.default)('motor_adapter_default'),
    motor_phase: (0, _defaultValue.default)('motor_phase_default'),
    motor_type: (0, _defaultValue.default)('motor_type_default'),
    express_head_in_pressure: (0, _defaultValue.default)('express_head_in_pressure_default'),
    wet_end_only: (0, _defaultValue.default)('wet_end_only_default'),
    include_motors: (0, _defaultValue.default)('include_motors_default'),

    specific_gravity: Ember.computed('fluid', {
      get: function get() {
        if (Ember.isNone(this.get('_specific_gravity')) && Ember.isNone(this.get('fluid'))) {
          return 1;
        } else if (Ember.isNone(this.get('_specific_gravity'))) {
          return this.get('fluid.specificGravity');
        } else {
          return this.get('_specific_gravity');
        }
      },
      set: function set(key, value) {
        var floatValue = parseFloat(value);

        // If unable to parse, NaN will be returned and of course it's a pain in the arse
        // to check for. Need to use isNaN to detect instead of equality. Could also possibly
        // use `floatValue == floatValue` in case there are weird issues with `isNaN`.
        if (isNaN(floatValue)) {
          this.set('_specific_gravity', null);
        } else {
          this.set('_specific_gravity', floatValue);
        }

        return this.get('_specific_gravity');
      }
    }),
    isSpecificGravityBlank: Ember.computed('specific_gravity', function () {
      return Ember.isNone(this.get('specific_gravity')) || this.get('specific_gravity') <= 0;
    }),

    englishFlow: Ember.computed('flow_unit', 'design_flow', function () {
      if (!Ember.isBlank(this.get('design_flow'))) {
        return new _conversions.Flow(this.get('flow_unit'), this.get('design_flow')).convertTo('GPM');
      }
    }),

    englishHead: Ember.computed('head_unit', 'design_head', 'specific_gravity', function () {
      if (!Ember.isBlank(this.get('design_head'))) {
        return new _conversions.Head(this.get('head_unit'), this.get('design_head'), this.get('specific_gravity')).convertTo('FT');
      }
    }),

    fluid: Ember.computed({
      get: function get() {
        if (!Ember.isNone(this.get('_fluid'))) {
          return this.get('_fluid');
        } else {
          return this.get('store').peekRecord('fluid', 121);
        }
      },
      set: function set(key, value) {
        if (value) {
          this.set('_fluid', value);
          this.set('_specific_gravity', value.get('specificGravity'));
        } else {
          this.set('_fluid', null);
          this.set('_specific_gravity', null);
        }

        return value;
      }
    }),

    isFlammable: Ember.computed('temperature,temperature_unit', 'fluid.{id,flashPointF}', function () {
      if (!Ember.isEmpty(this.get('fluid.flashPointF'))) {
        var chemTemp = new _conversions.Temperature(this.get('temperature_unit'), this.get('temperature')).convertTo('F');
        // Lower the flash point 10 more degrees for a safety margin.
        var flashPointSafetyMargin = this.get('fluid.flashPointF') - 10;

        // Always consider any flash point 140 or below flammable -- Replicates functionality from George's calculator
        if (this.get('fluid.flashPointF') <= 141 || chemTemp >= flashPointSafetyMargin) {
          return true;
        }
      }

      return false;
    }),

    resetToDefaults: function resetToDefaults() {
      this.setProperties({
        design_flow: null,
        design_head: null,
        specific_gravity: this.get('specific_gravity'),
        units: this.get('units_default'),
        viscosity: this.get('viscosity_default'),
        viscosity_unit: this.get('viscosity_unit_default'),
        temperature_unit_selected: this.get('temperature_units_default'),
        temperature: this.get('temperature_default'),
        frequency: this.get('frequency_default'),
        pump_seri: this.get('pump_seri_default'),
        motor_speed: this.get('motor_speed_default'),
        motor_size: this.get('motor_size_default'),
        motor_adapter: this.get('motor_adapter_default'),
        motor_phase: this.get('motor_phase_default'),
        motor_type: this.get('motor_type_default'),
        include_motors: this.get('include_motors_default')
      });
    }
  });
});